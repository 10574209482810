export default function Button(args: { [key: string]: any }) {
  const updateFollow = async (e: any, id: number) => {
    const save = async () => {
      return await (
        await fetch(`/api/follows/${id}/${e.target.checked ? 1 : 0}`, {
          method: "POST",
        })
      ).json();
    };
    args.callback(id, save);
  };

  return (
    <label className="check">
      <input
        type="checkbox"
        onChange={(e) => updateFollow(e, args.row.id)}
        defaultChecked={args.row.Follower.length !== 0}
      />
      <div className="btn btn-danger btn-sm" style={{ width: "120px" }}>
        フォロー解除
      </div>
      <div className="btn btn-primary btn-sm" style={{ width: "120px" }}>
        フォローする
      </div>
    </label>
  );
}
